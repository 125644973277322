import { SvgIconComponent } from "@material-ui/icons";
import React from "react";

interface ContactInfoItemProps {
  icon: SvgIconComponent;
  title: string;
}

const ContactInfoItem: React.FC<ContactInfoItemProps> = ({
  icon: Icon,
  title,
  children,
}) => {
  return (
    <li className="flex mb-4 last:mb-0">
      <Icon color="primary" className="text-2xl sm:text-3xl" />
      <ul className="ml-3">
        <li className="sm:text-lg text-base text-text-heading font-medium mb-1">
          {title}
        </li>
        {children}
      </ul>
    </li>
  );
};

export default ContactInfoItem;
