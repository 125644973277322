import { FieldConfig, useField } from "formik";
import React from "react";

interface FormFieldProps {
  placeholder?: string;
  label?: string;
  textarea?: boolean;
  rows?: number;
  className?: string;
}

const FormField: React.FC<FieldConfig<any> & FormFieldProps> = ({
  placeholder,
  label,
  textarea,
  rows,
  type,
  className = "",
  ...props
}) => {
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : "";

  const classes = `appearance-none border border-gray-200 w-full py-2 px-5 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent ${
    errorText ? "border-red-500 ring-red-500 ring-1" : ""
  }`;

  return (
    <div className={className}>
      {label ? <div>{label}</div> : null}

      {textarea ? (
        <textarea
          {...field}
          {...props}
          className={classes}
          placeholder={placeholder}
          rows={rows}
        />
      ) : (
        <input
          {...field}
          {...props}
          className={classes}
          placeholder={placeholder}
          type={type}
        />
      )}

      {errorText ? (
        <div className="text-sm text-red-500 mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormField;
