import { Form, Formik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import FormField from "./FormField";
import axios from "axios";
import { AlertError, AlertSucess } from "../../Alert/Alert";

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = ({}) => {
  const [wasSubmitted, setWasSubmitted] = useState<{ status?: number }>({});

  return (
    <div>
      {wasSubmitted && wasSubmitted.status ? (
        wasSubmitted?.status === 200 ? (
          <AlertSucess className="mb-4" heading="Thank you for contacting us.">
            We'll get back in touch with you within 24h. Have a great day!
          </AlertSucess>
        ) : (
          <AlertError className="mb-4">Something went wrong</AlertError>
        )
      ) : null}
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          // console.log(data);

          const res = await axios.post("/.netlify/functions/sendmail", data);

          if (res) {
            setWasSubmitted({ status: res.status });
          }

          if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              event: "form-sent",
            });
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormField
              name="name"
              placeholder="Name"
              type="text"
              className="mb-4"
            />

            <FormField
              name="email"
              placeholder="Email"
              type="email"
              className="mb-4"
            />

            <FormField
              name="phone"
              placeholder="Phone"
              type="text"
              className="mb-4"
            />

            <FormField
              name="message"
              placeholder="Message"
              type="textarea"
              textarea
              rows={4}
              className="mb-4"
            />
            <div>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
                size="large"
                className="rounded-none"
              >
                Send a message
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;

const validationSchema = yup.object({
  name: yup.string().required("Please include your name."),
  email: yup.string().email().required("Please include a valid email address."),
  phone: yup.string().required("Please include your phone number."),
  message: yup.string(),
});
