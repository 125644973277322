import React from "react";

import { DoneAll, Error } from "@material-ui/icons";

interface AlertProps {
  className?: string;
  heading?: string;
}

const alertClasses = "py-3 px-4 flex items-center";

export const AlertSucess: React.FC<AlertProps> = ({
  className = "",
  heading,
  children,
}) => {
  return (
    <div className={`bg-green-500 text-white ${alertClasses} ${className}`}>
      <div className="mr-3">
        <DoneAll />
      </div>
      <div className="py-2">
        {heading ? (
          <h5 className="text-lg font-semibold mb-1 text-white">{heading}</h5>
        ) : null}

        <p>{children}</p>
      </div>
    </div>
  );
};

export const AlertError: React.FC<AlertProps> = ({
  className = "",
  heading,
  children,
}) => {
  return (
    <div className={`bg-red-500 text-white ${alertClasses} ${className}`}>
      <div className="mr-3">
        <Error />
      </div>
      <div className="py-1">
        {heading ? (
          <h5 className="text-lg font-semibold mb-1 text-white">{heading}</h5>
        ) : null}

        <p>{children}</p>
      </div>
    </div>
  );
};
